import React from 'react';
import { motion } from 'framer-motion';

export const Spinner = () => {
  return (
    <motion.div
      className="w-48"
      animate={{ rotate: 360 }}
      transition={{
        duration: 1,
      }}
    >
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 240 240"
      >
        <path
          d="M108.61,165.35c3.65-10,17.63-10,21,0L150,224.88A11.43,11.43,0,0,1,139.29,240H97.23a11.16,11.16,0,0,1-10.35-15.12Z"
          fill="#e57a66"
        />
        <path
          d="M108.61,73.91c3.65,9.91,17.63,9.91,21,0L150,15a11.34,11.34,0,0,0-10.7-15H97.23A11.07,11.07,0,0,0,86.88,15Z"
          fill="#e57a66"
        />
        <path
          d="M145.25,161.86c-4.45-9.6,5.53-19.52,15-14.93l56.46,27.3a11.31,11.31,0,0,1,3,18.18l-30,29.83a11.17,11.17,0,0,1-18-3.25Z"
          fill="#e57a66"
        />
        <path
          d="M145.25,81.37c-4.45,9.6,5.53,19.51,15,14.92L216.73,69a11.32,11.32,0,0,0,3-18.19L189.75,21a11.17,11.17,0,0,0-18,3.25Z"
          fill="#e57a66"
        />
        <path
          d="M93.94,161.05c4.45-9.6-5.52-19.52-15-14.93l-56.47,27.3a11.32,11.32,0,0,0-3,18.19l30,29.83a11.18,11.18,0,0,0,18-3.26Z"
          fill="#63d1d2"
        />
        <path
          d="M93.94,81.37c4.45,9.6-5.52,19.51-15,14.92L22.46,69a11.32,11.32,0,0,1-3-18.19L49.44,21a11.17,11.17,0,0,1,18,3.25Z"
          fill="#e57a66"
        />
        <path
          d="M165.35,130.3c-10-3.69-10-17.85,0-21.3l59.53-20.59A11.45,11.45,0,0,1,240,99.25v42.58a11.17,11.17,0,0,1-15.12,10.47Z"
          fill="#e57a66"
        />
        <path
          d="M73.91,130.3c9.91-3.69,9.91-17.85,0-21.3L15,88.41A11.36,11.36,0,0,0,0,99.25v42.58A11.08,11.08,0,0,0,15,152.3Z"
          fill="#e57a66"
        />
      </svg>
    </motion.div>
  );
};
