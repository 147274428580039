import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import cx from 'classnames';
import { motion } from 'framer-motion';
import { useLocation } from '@reach/router';

import ArrowRight from '../../svg/arrowRight.svg';

export const SubscribeForm = () => {
  const { pathname } = useLocation();

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validate={(values) => {
        const errors = {};

        if (!values.email) {
          errors.email = 'Email address is Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = 'Invalid email address';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting, setStatus }) => {
        setSubmitting(true);
        await fetch(`/.netlify/functions/mc-subscribe`, {
          method: `POST`,
          body: JSON.stringify({ ...values, isEarly: true }),
          headers: {
            'content-type': `application/json`,
          },
        }).then((res) => {
          setSubmitting(false);
          setStatus('success');
        });
      }}
    >
      {({
        onChange,
        isSubmitting,
        status,
        values,
        /* and other goodies */
      }) => (
        <Form className="py-4">
          {status === 'success' ? (
            <div className="col-span-full text-left">
              <motion.h2
                className="font-display text-2xl"
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
              >
                Thank you we will be in touch.
              </motion.h2>
            </div>
          ) : (
            <>
              <div className="grid grid-cols-2">
                <div className="col-start-1 col-end-3 row-start-1">
                  <Field
                    type="email"
                    name="email"
                    className="bg-transparent w-full py-2 px-2 border-b border-current appearance-none placeholder-current"
                    placeholder="Email Address"
                  />
                  <ErrorMessage name="firstName" className="text-sm" component="div" />
                </div>
                <button
                  type="submit"
                  className={cx(
                    'col-start-2 row-start-1 self-center justify-self-end cursor-pointer pl-10',
                    { 'animate-pulse': isSubmitting }
                  )}
                  disabled={isSubmitting}
                >
                  <ArrowRight className="w-3 fill-current text-peach-100" />
                </button>
              </div>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};
