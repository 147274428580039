import React from 'react';
import PortableText from 'react-portable-text';
import cx from 'classnames';

// Block Components
import { AnchorScrollLink, ExternalLink, PageAnchorLink, PageLink, PathLink } from '../ui/links';
import { ImageModule, VideoModule } from '../modules';
import { SoundEmbed } from '../modules/soundEmbed';

const serializers = {
  h1: (props) => <h1 {...props} className="isH1" />,
  h2: (props) => <h2 {...props} className="isH2" />,
  h3: (props) => <h3 {...props} className="isH3" />,
  h4: (props) => <h4 {...props} className="isH4" />,
  h5: (props) => <h5 {...props} className="isH5" />,
  h6: (props) => <h6 {...props} className="isH6" />,
  'super-script': (props) => <sup>{props.children}</sup>,
  'sub-script': (props) => <sub>{props.children}</sub>,
  pageLink: ({ buttonDisplay, ...props }) => (
    <PageLink
      {...props}
      className={cx({
        'btn text-white no-underline': buttonDisplay,
        'border-b border-mint-100': !buttonDisplay,
      })}
    />
  ),
  internalLink: ({ children, ...props }) => (
    <PageLink {...props} linkText={children} className="border-b border-mint-100" />
  ),

  pathLink: (props) => <PathLink {...props} className="underline" />,
  anchorLink: (props) => <AnchorScrollLink {...props} className="underline" />,
  externalLink: (props) => <ExternalLink {...props} className="underline" />,
  pageAnchorLink: (props) => <PageAnchorLink {...props} className="underline" />,
  normal: (props) => <p {...props} />,
  imageModule: (props) => <ImageModule {...props} />,
  videoModule: (props) => <VideoModule {...props} />,
  highlight: (props) => <span {...props} className="text-mint-50" />,
  soundEmbed: (props) => <SoundEmbed {...props} />,
  link: (props) => (
    <a className="border-b border-mint-100" href={props.url}>
      {props.children}
    </a>
  ),
}; // eslint-disable-line

export const PortableTextBlock = ({ text, ...props }) => {
  if (!text) {
    return null;
  }
  return <PortableText content={text} serializers={serializers} {...props} />;
};
