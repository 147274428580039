import React from 'react';

import { motion } from 'framer-motion';

import { Spinner } from './spinner';

export const PageLoader = () => {
  return (
    <>
      {/* Mount In */}
      <motion.div
        className="slide-in"
        initial={{ scaleX: 0 }}
        animate={{ scaleX: 0 }}
        exit={{ scaleX: 1 }}
        transition={{ duration: 1, ease: [0.43, 0.13, 0.23, 0.96] }}
      />
      <motion.div
        className="w-full h-full fixed top-0 left-0 flex items-center justify-center pointer-events-none"
        initial={{ opacity: 0 }}
        animate={{ opacity: 0 }}
        exit={{ opacity: 1 }}
        transition={{ duration: 1, ease: [0.43, 0.13, 0.23, 0.96] }}
        style={{ zIndex: 6000 }}
      >
        <Spinner />
      </motion.div>

      {/* Mount Out */}
      <motion.div
        className=" w-full h-full fixed top-0 left-0 flex items-center justify-center pointer-events-none"
        initial={{ opacity: 1 }}
        animate={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1, ease: [0.43, 0.13, 0.23, 0.96] }}
        style={{ zIndex: 6000 }}
      >
        <Spinner />
      </motion.div>
      <motion.div
        className="slide-out flex items-center justify-center"
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0 }}
        exit={{ scaleX: 0 }}
        transition={{ duration: 1, ease: [0.43, 0.13, 0.23, 0.96] }}
      />
    </>
  );
};
