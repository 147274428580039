import React from 'react';
import loadable from '@loadable/component';

import { useInView } from 'react-intersection-observer';

const PlayerComponent = loadable(() => import('../media/soundPlayer'));

export const SoundEmbed = ({ url }) => {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.5,
    triggerOnce: true,
  });
  return (
    <div className="bg-grey-800" ref={ref}>
      {inView && <PlayerComponent url={url} width="100%" />}
    </div>
  );
};
