import React from 'react';
import cx from 'classnames';

import '../css/main.css';

import { Header } from '../components/nav/header';
import { Footer } from '../components/nav/footer';
import { CookieConsent } from '../components/nav/cookieConsent';
import { PageLoader } from '../components/animation/pageLoader';

const Layout = ({ children, theme }) => {
  const { bg, footerStyle } = theme || {};
  return (
    <>
      <Header />
      <main
        className={cx('w-full absolute top-0 left-0 min-h-screen', {
          'bg-mint-5': bg === 'mint',
          'bg-peach-5': bg === 'peach',
        })}
      >
        {children}
        <Footer footerStyle={footerStyle} />
      </main>
      <PageLoader />
      <CookieConsent />
    </>
  );
};

export default Layout;
