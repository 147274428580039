import React, { useState } from 'react';
import Headroom from 'react-headroom';
import cx from 'classnames';
// Hooks & Context
import { useSiteConfig } from '../../GraphQl/useSiteConfig';
import { useNav } from '../../lib/context';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Nav Link
import { NavLink } from './navLink';
import { HomeLink } from './homeLink';
import { MenuToggle } from './menuToggle';
import { MobileNav } from './mobileNav';

export const Header = () => {
  const { mainNav, minorLinks, socialHandles } = useSiteConfig();

  const [navOpen, setNavOpen] = useState(false);
  const [headerPinned, setHeaderPinned] = useState(false);
  const { setReverseHeader, reverseHeader } = useNav();
  const breakpoints = useBreakpoint();
  const toggleNav = () => setNavOpen((prev) => !prev);

  const headerClasses = cx({
    'flex px-gutter py-4 justify-between site-header items-center lg:py-7 transition-all duration-200': true,
    'text-white': reverseHeader && !headerPinned,
    'text-grey-800': !reverseHeader || headerPinned,
    'lg:bg-white': headerPinned,
    'bg-white': headerPinned && !navOpen,
  });

  return (
    <>
      <Headroom
        style={{ zIndex: '2500' }}
        onPin={() => setHeaderPinned(true)}
        onUnpin={() => setHeaderPinned(false)}
        onUnfix={() => setHeaderPinned(false)}
      >
        <header className={headerClasses}>
          <HomeLink reverseHeader={reverseHeader} headerPinned={headerPinned} navOpen={navOpen} />
          <div className="flex">
            <nav className="hidden lg:flex gap-x-6 items-center">
              {mainNav.map(({ buttonDisplay, ...item }) => {
                const linkClasses = cx({
                  'px-7 py-2 uppercase text-xs text-white bg-peach-100 rounded-lg font-display hover:bg-peach-75 transition-colors duration-75':
                    buttonDisplay,
                  '': !buttonDisplay,
                });
                return <NavLink className={linkClasses} {...item} />;
              })}
            </nav>
            <MenuToggle
              isOpen={navOpen}
              onClick={toggleNav}
              reverseHeader={reverseHeader}
              className="lg:hidden"
            />
          </div>
        </header>
      </Headroom>
      {breakpoints.md && (
        <MobileNav
          links={mainNav}
          minorLinks={minorLinks}
          socialHandles={socialHandles}
          isOpen={navOpen}
        />
      )}
    </>
  );
};
