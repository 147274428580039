import React, { useState, useEffect } from 'react';
import useCookie from 'react-use-cookie';
import { Link } from 'gatsby';
import { AnimatePresence, motion } from 'framer-motion';

import MessageIcon from '../../svg/message.svg';
import { PortableTextBlock } from '../sanity/portableTextBlock';
import { useSiteConfig } from '../../GraphQl/useSiteConfig';

export const CookieConsent = () => {
  const [cookieAccept, setCookieAccept] = useCookie('cookie-accept', false);
  const [show, setShow] = useState(false);
  const { cookieText } = useSiteConfig();

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 2000);
  }, []);

  const verifyCookie = () => {
    setShow(false);
    setTimeout(() => {
      setCookieAccept(true);
    }, 200);
  };

  if (cookieAccept) return false;

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          className="fixed bottom-0 left-0 w-full bg-grey-800 px-gutter text-white py-4 z-2500"
          initial={{ y: '100%', opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: '100%', opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <div className="max-w-7xl mx-auto flex flex-col gap-y-3 md:flex-row md:justify-center md:gap-x-3">
            <div className="text-lg flex gap-x-3 items-center">
              <div className="hidden md:block">
                <MessageIcon className="fill-current text-mint-100 w-4" />
              </div>
              {/* <p>
                We use cookies to improve your experience. Read our{' '}
                <Link to="/privacy-policy" className="text-mint-100">
                  privacy policy
                </Link>{' '}
                to learn more
              </p> */}
              <PortableTextBlock text={cookieText} />
            </div>
            <div className="flex gap-x-3">
              <a
                href="https://www.google.com"
                className="px-7 py-2 uppercase text-base bg-transparent text-current border-2 border-current rounded-lg font-display"
              >
                DECLINE
              </a>
              <button
                className="px-7 py-2 uppercase text-base bg-peach-100 text-white rounded-lg font-display"
                onClick={verifyCookie}
              >
                ACCEPT
              </button>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
