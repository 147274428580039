import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

// Hooks
import { useScrollFreeze } from '../../hooks/useScrollFreeze';

import { NavLink } from './navLink';

const navVars = {
  open: {
    y: '0%',
    transition: {
      duration: 0.4,
      ease: 'easeInOut',
      when: 'beforeChildren',
    },
  },
  closed: {
    y: '-100%',
    transition: {
      duration: 0.4,
      ease: 'easeInOut',
    },
  },
};

const linkVars = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.2,
    },
  },
  closed: {
    y: -50,
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
};

const NavInner = ({ links, socialHandles, minorLinks }) => {
  useScrollFreeze();

  return (
    <motion.div
      initial="closed"
      animate="open"
      exit="closed"
      className="h-full w-full fixed top-0 left-0 bg-peach-100 text-white z-2000"
      variants={navVars}
    >
      <div className="px-gutter pt-32">
        <motion.nav className="grid gap-y-2">
          {links.map((item) => (
            <motion.div variants={linkVars}>
              <NavLink {...item} className="font-display text-2xl py-2" />
            </motion.div>
          ))}
        </motion.nav>
        <motion.nav className="border-t border-current mt-6 py-6" variants={linkVars}>
          <div className="flex flex-col gap-y-2">
            {minorLinks.map((item) => (
              <NavLink {...item} />
            ))}
          </div>
          <div className="flex gap-x-4 mt-6">
            {socialHandles.map(({ link, icon }) => (
              <a href={link} target="_blank">
                <div dangerouslySetInnerHTML={{ __html: icon }} className="w-8" />
              </a>
            ))}
          </div>
        </motion.nav>
      </div>
    </motion.div>
  );
};

export const MobileNav = ({ isOpen, ...rest }) => {
  return <AnimatePresence>{isOpen && <NavInner {...rest} />}</AnimatePresence>;
};
