import React from 'react';
import { motion } from 'framer-motion';
import cx from 'classnames';

// Animation
const top = {
  closed: {
    rotate: 0,
    translateY: 0,
  },
  opened: {
    rotate: 45,
    translateY: 2,
  },
};

const bottom = {
  closed: {
    rotate: 0,
    translateY: 0,
  },
  opened: {
    rotate: -45,
    translateY: -2,
  },
};

export const MenuToggle = ({ isOpen, reverseHeader, width = 15, height = 6, ...props }) => {
  const variant = isOpen ? 'opened' : 'closed';
  const unitHeight = 4;
  const unitWidth = (unitHeight * width) / height;

  const classNames = cx({
    'rounded-full w-10 h-10 flex items-center justify-center transition-colors duration-200 lg:hidden': true,
    'text-white bg-transparent': isOpen,
    'text-grey-800 bg-white': !isOpen,
    'border border-current': !reverseHeader && !isOpen,
  });

  return (
    <div {...props} className={classNames}>
      <svg
        viewBox={`0 0 ${unitWidth} ${unitHeight}`}
        overflow="visible"
        preserveAspectRatio="none"
        width={width}
        height={height}
        strokeWidth="2.5"
        className="stroke-current"
      >
        <motion.line
          x1="0"
          x2={unitWidth}
          y1="0"
          y2="0"
          variants={top}
          animate={variant}
          initial="closed"
          vectorEffect="non-scaling-stroke"
        />
        <motion.line
          x1="0"
          x2={unitWidth}
          y1="4"
          y2="4"
          variants={bottom}
          animate={variant}
          initial="closed"
          vectorEffect="non-scaling-stroke"
        />
      </svg>
    </div>
  );
};
