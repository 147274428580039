import React from 'react';
import cx from 'classnames';

import { useSiteConfig } from '../../GraphQl/useSiteConfig';

import { SubscribeForm } from '../forms/subscribeForm';
import { SocialLink } from '../ui/socialLink';
import { PortableTextBlock } from '../sanity/portableTextBlock';
import { NavLink } from './navLink';
import { FooterLogo } from './footerLogo';

export const Footer = ({ footerStyle }) => {
  const { socialHandles, contactDetails, footerNav } = useSiteConfig();

  return (
    <footer
      className={cx('px-gutter py-10 grid lg:grid-cols-6 lg:gap-x-10 xl:grid-cols-7', {
        'bg-peach-5': footerStyle === 'peach',
        'bg-mint-5': footerStyle === 'mint',
        'bg-peach-100 text-white': footerStyle === 'dark-peach',
      })}
    >
      {/* Follow */}
      <div className="grid gap-y-4 lg:col-span-2 ">
        <div>
          <h4 className="isH4">Stay Connected</h4>
          <SubscribeForm />
        </div>
        <div>
          <h4 className="isH4">Follow Us</h4>
          <div className="flex gap-x-8 mt-4">
            {socialHandles.map((item) => (
              <SocialLink
                {...item}
                className={cx('w-4', {
                  'text-peach-100': footerStyle !== 'dark-peach',
                  'text-mint-50': footerStyle === 'dark-peach',
                })}
              />
            ))}
          </div>
        </div>
      </div>
      {/* Contact Details */}
      <div className="grid gap-y-6 mt-10 lg:col-span-2 lg:mt-0">
        {contactDetails.map(({ title, text }) => {
          return (
            <div>
              <h4 className="isH4 mb-2">{title}</h4>
              <PortableTextBlock text={text} className="text-sm leading-6" />
            </div>
          );
        })}
      </div>
      {/* Links */}
      <div
        className={cx('grid grid-cols-2 gap-y-4 mt-6 lg:mt-0 lg:col-span-2', {
          'text-peach-100': footerStyle !== 'dark-peach',
        })}
      >
        {footerNav.map((item) => (
          <NavLink {...item} />
        ))}
      </div>
      {/* bottom */}
      <div className="mt-10 lg:col-span-full">
        <div className="flex flex-col gap-y-6 lg:flex-row-reverse lg:gap-x-10">
          <FooterLogo reversed={footerStyle === 'dark-peach'} className="w-32 lg:w-48" />
          <span
            className={cx('border-t w-full  lg:self-center', {
              'border-current': footerStyle === 'dark-peach',
              'border-peach-100': footerStyle !== 'dark-peach',
            })}
          />
        </div>
        <p className={cx('text-xs mt-4', { 'text-grey-500': footerStyle !== 'dark-peach' })}>
          &copy; Copyright 2021 Pomelo - All rights reserved.
        </p>
      </div>
    </footer>
  );
};
