import React from 'react';
import cx from 'classnames';

export const SocialLink = ({ link, icon, className }) => {
  return (
    <a href={link} target="_blank" className={cx(className, { 'social-link': true })}>
      <div dangerouslySetInnerHTML={{ __html: icon }} className="fill-current" />
    </a>
  );
};
