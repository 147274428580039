import { graphql, useStaticQuery } from 'gatsby';

import { QueryFragments } from './queryFragments'; // eslint-disable-line

export const useSiteConfig = () => {
  const { sanitySiteConfig } = useStaticQuery(graphql`
    query {
      sanitySiteConfig {
        mainNav {
          ... on SanityPageLink {
            _type
            ...PageLinkFields
          }
          ... on SanityPathLink {
            _type
            ...PathLinkFields
          }
        }
        footerNav {
          ... on SanityPageLink {
            _type
            ...PageLinkFields
          }
          ... on SanityPathLink {
            _type
            ...PathLinkFields
          }
        }
        socialHandles {
          link
          icon
        }
        contactDetails {
          ...TextSection
        }
        minorLinks {
          ... on SanityPageLink {
            _key
            _type
            ...PageLinkFields
          }
          ... on SanityPathLink {
            _key
            _type
            ...PathLinkFields
          }
        }
        cookieText: _rawCookieText(resolveReferences: { maxDepth: 10 })
      }
    }
  `);
  return sanitySiteConfig || {};
};
