import React from 'react';

import { Link } from 'gatsby';
import cx from 'classnames';

export const FooterLogo = ({ reversed, ...rest }) => {
  const textClasses = cx('fill-current transition-color duration-200', {
    'text-white': reversed,
    'text-peach-100': !reversed,
  });
  const wedgeClasses = cx('fill-current transition-color duration-200 text-mint-100');
  return (
    <Link to="/" {...rest}>
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 224.77 23.83"
      >
        <path
          d="M18.82,1.55a3.33,3.33,0,0,1,4.54,4.33L15.63,22.76a3.38,3.38,0,0,1-5.39,1L1.16,15.12A3.29,3.29,0,0,1,2,9.77Z"
          transform="translate(-0.14 -0.89)"
          className={wedgeClasses}
        />
        <path
          d="M47.37,1.13H41.26a2.91,2.91,0,0,0-3,3V22A2.75,2.75,0,0,0,41,24.73,2.73,2.73,0,0,0,43.7,22v-5.5h3.67c5.2,0,8.57-3.46,8.57-7.63S52.57,1.13,47.37,1.13Zm-.3,10.7H43.7v-6h3.37c1.9,0,3.56.57,3.56,3.07S49,11.83,47.07,11.83Z"
          transform="translate(-0.14 -0.89)"
          className={textClasses}
        />
        <path
          d="M80.26.89a11.83,11.83,0,0,0-11.64,11.9,11.64,11.64,0,1,0,23.28,0A11.83,11.83,0,0,0,80.26.89Zm0,5.2c3.3,0,5.87,2.7,5.87,6.7s-2.63,6.67-5.87,6.67-5.9-2.67-5.9-6.67S77,6.09,80.26,6.09Z"
          transform="translate(-0.14 -0.89)"
          className={textClasses}
        />
        <path
          d="M128.39,1a3.5,3.5,0,0,0-2.74,1.8l-7.34,12.93L111,2.83A3.51,3.51,0,0,0,108.27,1a2.48,2.48,0,0,0-2.53,2.6V21.76a2.8,2.8,0,1,0,5.6,0V13.19L116,22a2.58,2.58,0,0,0,2.33,1.53A2.68,2.68,0,0,0,120.68,22l4.64-8.77v8.57a2.8,2.8,0,0,0,2.73,2.8,2.88,2.88,0,0,0,2.87-2.8V3.63A2.49,2.49,0,0,0,128.39,1Z"
          transform="translate(-0.14 -0.89)"
          className={textClasses}
        />
        <path
          d="M150.57,19.69V15h5a2.36,2.36,0,0,0,2.41-2.3,2.37,2.37,0,0,0-2.41-2.27h-5V5.89h8.1a2.42,2.42,0,0,0,2.44-2.43,2.4,2.4,0,0,0-2.44-2.33H148.13a2.91,2.91,0,0,0-3,3V21.43a2.92,2.92,0,0,0,3,3H159a2.39,2.39,0,1,0,0-4.77Z"
          transform="translate(-0.14 -0.89)"
          className={textClasses}
        />
        <path
          d="M180.25,19.63V3.83a2.75,2.75,0,0,0-2.7-2.8,2.78,2.78,0,0,0-2.77,2.8v17.6a2.93,2.93,0,0,0,3,3h10.07a2.42,2.42,0,0,0,0-4.83Z"
          transform="translate(-0.14 -0.89)"
          className={textClasses}
        />
        <path
          d="M213.26.89a11.83,11.83,0,0,0-11.64,11.9,11.64,11.64,0,1,0,23.28,0A11.83,11.83,0,0,0,213.26.89Zm0,5.2c3.31,0,5.87,2.7,5.87,6.7s-2.63,6.67-5.87,6.67-5.9-2.67-5.9-6.67S210,6.09,213.26,6.09Z"
          transform="translate(-0.14 -0.89)"
          className={textClasses}
        />
      </svg>
    </Link>
  );
};
